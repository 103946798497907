.dataimg-aquarius {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/aquarius.svg"); background-repeat: no-repeat;}

.dataimg-aries {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/aries.svg"); background-repeat: no-repeat;}

.dataimg-arrow-left {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/arrow-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-left {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/arrow-light-left.svg"); background-repeat: no-repeat;}

.dataimg-arrow-light-right {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/arrow-light-right.svg"); background-repeat: no-repeat;}

.dataimg-arrow-right {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/arrow-right.svg"); background-repeat: no-repeat;}

.dataimg-bublina {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/bublina.svg"); background-repeat: no-repeat;}

.dataimg-cancer {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/cancer.svg"); background-repeat: no-repeat;}

.dataimg-capricorn {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/capricorn.svg"); background-repeat: no-repeat;}

.dataimg-cross-thin {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/cross-thin.svg"); background-repeat: no-repeat;}

.dataimg-facebook {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/facebook.svg"); background-repeat: no-repeat;}

.dataimg-fb-square {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/fb-square.svg"); background-repeat: no-repeat;}

.dataimg-gemini {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/gemini.svg"); background-repeat: no-repeat;}

.dataimg-instagram-3 {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/instagram-3.svg"); background-repeat: no-repeat;}

.dataimg-leo {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/leo.svg"); background-repeat: no-repeat;}

.dataimg-libra {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/libra.svg"); background-repeat: no-repeat;}

.dataimg-login {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/login.svg"); background-repeat: no-repeat;}

.dataimg-logo-horizontal {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/logo-horizontal.svg"); background-repeat: no-repeat;}

.dataimg-logo-onecolor {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/logo-onecolor.svg"); background-repeat: no-repeat;}

.dataimg-logo {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/logo.svg"); background-repeat: no-repeat;}

.dataimg-online-live {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/online-live.svg"); background-repeat: no-repeat;}

.dataimg-online {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/online.svg"); background-repeat: no-repeat;}

.dataimg-pisces {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/pisces.svg"); background-repeat: no-repeat;}

.dataimg-sagittarius {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/sagittarius.svg"); background-repeat: no-repeat;}

.dataimg-scorpio {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/scorpio.svg"); background-repeat: no-repeat;}

.dataimg-search {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/search.svg"); background-repeat: no-repeat;}

.dataimg-star-empty {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/star-empty.svg"); background-repeat: no-repeat;}

.dataimg-star {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/star.svg"); background-repeat: no-repeat;}

.dataimg-taurus {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/taurus.svg"); background-repeat: no-repeat;}

.dataimg-three-circles {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/three-circles.svg"); background-repeat: no-repeat;}

.dataimg-twitter {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/twitter.svg"); background-repeat: no-repeat;}

.dataimg-virgo {background-image: url("https://img2.cncenter.cz/images/supermamy/dist/svg/virgo.svg"); background-repeat: no-repeat;}